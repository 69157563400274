<template>
  <div>

    <b-container>
      <h2>Tecnologias</h2>


      <b-row>
        
        <div class="tecContent">

      <div class="col-5 textContent">
        <h3>Trabalhamos sempre com as melhores tecnologias para entregar sites otimizados e seguros.</h3>
        <p>Escolhemos as tecnologias de acordo com as necessidades de seu projeto</p>

        <b-row>
        <ul>
          <li>Wordpress</li>
          <li>Laravel</li>
          <li>Vue JS</li>
          <li>Node JS</li>
        </ul>

        <ul>
          <li>Photoshop</li>
          <li>Figma</li>
          <li>MySql</li>
          <li>Html / Css</li>
        </ul>
      </b-row>
      </div>

      <div class="col-7">
        <div class="CardContainer">
                    <div v-for="(item) in Tecs" :key="item.id" class="card">
                        <img class="icon" :src="item.icon" alt="">
                        <br/>
                        <!-- <p>{{item.name}}</p> -->
                        <br/>
                        <p>{{item.desc}}</p>
                    </div>
                </div>
      </div>
    </div>

    </b-row>

    <div class="banner">
      <h2>Conte conosco para o desenvolvimento de seu site!</h2>
      <button class="btnContato"><a :href="contato" target="_blank">Comece agora</a></button>
    </div>
    </b-container>


  </div>
</template>

<script>
export default {
name:'tecnologiasVue',

data(){
  return{
    contato:'https://api.whatsapp.com/send?phone=5512997916983',

    Tecs:[
                    {id:1, name:'Wordpress', icon:'./images/wordpress.png'},
                    {id:2, name:'Laravel', icon:'./images/laravel.png'},
                    {id:3, name:'Vue Js', icon:'./images/vue.png'},
                    {id:4, name:'Node Js', icon:'./images/node.png'},
                    {id:5, name:'Photoshop', icon:'./images/photoshop.png'},
                    {id:6, name:'Figma', icon:'./images/figma.png'},
                    
                ],
  }
}
}
</script>

<style scoped>
.tecContent{
    display: flex;
    flex-direction: row;
  }
.textContent{
  text-align: left;
  margin-top: 5%;
}
.textContent h3{
  font-weight: bold;
}

a{
    text-decoration: none;
    color: #fff;
}
a:hover{
    color: #0073e6;
}
ul{
margin-right: 15%;
}
li{
  font-size: 1.2em !important;

}

.CardContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  margin-top: 9%;
  justify-content: center;
  align-content: center;
}
.card{
  margin-bottom: 3%;
  padding: 7%;
  width: 30%;
  margin-right: 2%;
  height: 160px;
  border-radius: 14px;
}
.icon{
  margin-top: -10%;
}

.banner{
  background-color: #2C3E50;
  height: 300px;
  border-radius: 14px;
  padding: 3%;
  margin-top: 5%;
  margin-bottom: -15%;
  z-index: 1 !important;
  position: relative;
}
.banner h2{
  color: #fff;
  font-weight: bold;
  margin-top: 5%;
}

.btnContato{
  margin-top: 5%;
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size: 1.3em;
    width: 300px;
    border: solid 1px #0073e6;
    font-family: 'Montserrat' !important; 
    cursor: pointer;

    transition: all 0.3s;
    -webkit-transition: width 1s;
    transition: width 1s;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}


 .btnContato:hover{
    background-color: #fff;
    color: #0073e6 !important;
    border: solid 1px #0073e6;
    transform: scale(1.1);

 }
 .btnContato:hover a{
    color: #0073e6 !important;
 }

@media only screen and (max-width: 869px)  {
 

  .tecContent{
    display: flex;
    flex-direction: column;
    max-width: 360px;
  }
.textContent{
  text-align: center;
  min-width: 350px;
  justify-content: center;
  align-content: center;
  margin: 2%;
  
  
}
.textContent h3{
  font-weight: bold;
}
ul{
margin-right: 13%;
}
li{
  font-size: 1em !important;

}



.CardContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  margin-top: 9%;
  justify-content: center;
  align-content: center;
  min-width: 350px !important;

}
.card{
  margin-bottom: 3%;
  padding: 2%;
  width: 20%;
  margin-right: 5%;
  height: 75px;
  border-radius: 14px;
}
.icon{
  margin-top: 5% !important;
}

}

</style>