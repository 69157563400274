
<template>
    <b-navbar toggleable="md" type="light" variant="light" class="MenuContainer">
      <b-navbar-brand to="/">
        <img  class="logo" :src="logo" alt="Logo">
      </b-navbar-brand>
  
      <!-- Links -->
    
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
  
      <b-collapse is-nav id="nav_collapse" class="menuItem">
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#servicos">Serviços</b-nav-item>
          <b-nav-item href="#comofunciona">Como funciona</b-nav-item>
          <b-nav-item href="#sobre">Sobre</b-nav-item>
          <b-nav-item :href="portifolio" target="_blank" download="portifolio">Seja parceiro</b-nav-item>
          <b-nav-item  :href="contato" target="_blank">Contato</b-nav-item>          
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </template>

<script>
export default{
    name:'navBarVue',

    data(){
    return{
    contato:'https://api.whatsapp.com/send?phone=5512997916983',

    logo:'./images/logo.png',
    portifolio:'./conteudo/portifolio.pdf',
}
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color:#36383F !important;

}
a:hover{
    color: #0073E6 !important;
    text-decoration: none;
}
.menuItem{
    list-style: none;
    font-size: 1.2em;
    margin-top: -1%;

    color:#36383F !important; 

}
.Parceiro{
    color: #0073E6 !important;
}


.btn a{
    text-decoration: none;
    color: #0073E6!important;
}
.btn a:hover{
    text-decoration: none;
    color: #fff !important;
}
.btn:hover{
    border: none;
    color: #fff !important;
    background-color: #0073E6;

}
li{
    margin-right: 35px;
    cursor: pointer;
    font-weight:500;
    font-size: 1.2em;

}
li:hover{
    color: #0073E6 !important;
}
header{
    border: none !important;
}
.MenuContainer{
    background-color: #ffffff !important;
    top: 0;
    width: 100% !important;
    margin-top: -50px;
    border: none !important;
    margin-bottom: 5%;
    color:#36383F !important; 

    
}
.logo{
    margin-left: 20px;
    float: left;
    height: 90px;
    width: 100%;
}
.nav{
    width: 100%;
    overflow: hidden;
    color:#36383F;
    /* margin-right: 11%; */
}
.menu a{
    display: block;
    color:#36383F;
}
.menu a:hover{
    background-color: var(--gray);
}
.nav{
    max-height: 0;
    transition: max-height .5s ease-out;

}
.hamb{
    cursor: pointer;
    float: right;
    padding: 40px 20px;
}

.hamb_line {
    background: #36383F;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
} 
.hamb_line::before,
.hamb_line::after{
    background: #36383F;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
    color:red;
}
.hamb_line::before{
    top: 5px;
}
.hamb_line::after{
    top: -5px;
}

.side_menu {
    display: none;
} 

.side_menu:checked ~ nav{
    max-height: 100%;
}
.side_menu:checked ~ .hamb .hamb_line {
    background: transparent;
}
.side_menu:checked ~ .hamb .hamb_line::before {
    transform: rotate(-45deg);
    top:0;
}
.side_menu:checked ~ .hamb .hamb_line::after {
    transform: rotate(45deg);
    top:0;
}

@media (min-width: 768px) {
    .nav{
        max-height: none;
        top: 0;
        position: relative;
        float: right;
        width: fit-content;
    }
    .menu li{
        float: left;
    }
    .menu a:hover{
        background-color: transparent;
        color: var(--gray);

    }

    .hamb{
        display: none;
    }
}

@media only screen and (max-width: 869px)  {
  .header{
    background-color: #ffffff;
    position: sticky;
    top: 0;
    width: 100%;
    height: 150px;
  
}
img{
    float: left;
    height: 100px;
    top: 15% !important;
}

.logo{
    margin-left: 20px;
    float: left;
    height: 100px;
    width: 220px;
}

.btn{
    margin-top: 7px;
    width: 70%;
}

li{
    margin-top:10px;
}
} 
</style>