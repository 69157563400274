<template>
  <div class="container">
    <div class="bannerContainer">
        <b-row  class="align-items-center">
        <div class="texto col-lg-7 align-items-center">
            <div class="col-12 col-sm-12 TextContent">
            <h2 class="titulo">Desenvolvemos <span>websites</span> profissionais.</h2>
            <p>A CodeCompass desenvolve seu site de acordo com sua necessidade<span>.</span></p>
            <button class="btnContato"><a :href="contato" target="_blank">Entre em contato</a></button>
            </div>
        </div>

        <div class="imagem col-lg-5 mx-auto mx-lg-12 col-sm-12 position-relative">
            <transition name="slide">

            <img class="bannerImg" key="banner" :src="imagem" alt="">
            </transition>
        </div>
    </b-row>
    </div>
  </div>
</template>

<script>
export default {
name:'BannerVue',
data(){
    return{
    imagem:'./images/banner.png',
    showBanner: false,
    contato:'https://api.whatsapp.com/send?phone=5512997916983',


}

},
mounted() {
    this.showBanner = true;
  }
}
</script>

<style>
.slide-enter-active {
  transition: transform 0.5s;
}
.slide-enter {
  transform: translateX(100%);
}
</style>

<style scoped>

body{
    overflow-x: hidden !important;
    font-family: 'Montserrat Variable', sans-serif;
}

a{
    text-decoration: none;
    color: #fff;
}

.titulo{
    font-weight: bold;
    font-family: 'Montserrat Variable', sans-serif;
    font-size: 48px !important;
    width: 100% !important;
}
.TextContent{
    width: 100%;
}
.bannerContainer{
    display: flex;
    flex-direction: row;
    width: auto;
}

.texto{
    color: #2C3E50;
    text-align: left;
    font-size: 35px;
    width: 100%;
}
.texto h1{
    font-weight: bold;
    margin-bottom: 10%;

}
.texto span{
    color: #0073e6;
}
.texto p{
    color: #2C3E50;
    margin-bottom: 10%;

}
.btnContato{
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size: 0.8em;
    width: 300px;
    border: solid 1px #0073e6;
    font-family: 'Montserrat' !important; 
    cursor: pointer;

    transition: all 0.3s;
    -webkit-transition: width 1s;
    transition: width 1s;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}
.btnContato:hover{
    background-color: #fff;
    color: #0073e6;
    border: solid 1px #0073e6;
    transform: scale(1.1);

 }
 .btnContato:hover a{
    color: #0073e6 !important;
 }


.bannerImg{
    height:500px;
    width: 100%
}

img{
    height: 500px;
    position: relative;
    z-index: 100;
}


/* MOBILE */

@media  (min-width: 900px) and (max-width: 900px) {
    body{
        overflow-x: hidden !important;
    }
    .bannerContainer{
    background-color:blue ;
    width: 100%;
    margin: 0 auto !important;
}
}

@media only screen and (max-width: 869px)  {

    body{
        overflow-x: hidden !important;
    }
    .bannerContainer{
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
}
    .texto{
    color: #2C3E50;
    text-align: center;
    font-size: 30px;
}
.titulo{
    font-size: 36px !important;
}
.texto h1{
    font-weight: 700 !important;
    margin-bottom: 10%;
}
.texto span{
    color: #0073e6;
}
.texto p{
    color: #2C3E50;
    margin-bottom: 10%;

}
.btnContato{
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size: 0.8em;
    border: none;
    width: 300px;
    font-family: 'Montserrat' !important; 
    cursor: pointer;
    transition: width 0.3s; 
}
.btnContato:hover{
    background-color: #fff;
    color: #0073e6;
    border: solid 1px #0073e6;
 }

.bannerImg{
    padding: 5%;
    margin: 0 auto !important;
}

img{
    height: 400px !important;
    margin: 0 auto;
    width: 100%;

}
}

/* 1080P + */
@media  (min-width: 1800px) and (max-width: 1800px) {
    body{
    overflow-x: hidden;
    font-family: 'Montserrat' !important; 

}
.bannerContainer{
    display: flex;
    flex-direction: row;
}

.texto{
    color: #2C3E50;
    text-align: left;
    font-size: 30px;
}
.texto h1{
    font-weight: 700 !important;
    margin-bottom: 10%;
}
.texto span{
    color: #0073e6;
}
.texto p{
    color: #2C3E50;
    margin-bottom: 10%;

}
.btnContato{
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size: 0.8em;
    border: none;
    width: 300px;
    font-family: 'Montserrat' !important; 
    cursor: pointer;
    transition: width 0.3s; 
}
.btnContato:hover{
    width: 350px;
    transition: width 0.3s;
    background-color: #fff;
    color: #0073e6;
    border: solid 1px #0073e6;
 }

.bannerImg{
    height:300px;
    width: 100%
}
}

</style>