<template>
  <div>

        <h2>Últimos projetos</h2>

    <div class="ContainerProjetos position-relative align-center">
        <div class="FCollum">

            <a href="https://armbd.com.br/" target="_blank">
            <div class="card1" v-bind:style="{ backgroundImage: 'url(' + projeto1 + ')', backgroundPosition: 'center center', backgroundSize: 'cover', repeat:'noRepeat'}">
                        <h3>Landing page ARMBD</h3>
                    </div>
                </a>
                
                <a href="https://gbmagazine.lojavirtualnuvem.com.br/" target="_blank">
                    <div class="card2" v-bind:style="{ backgroundImage: 'url(' + projeto4 + ')', backgroundPosition: 'center center', backgroundSize: 'cover' }">
                        <h3>E-commerce GBMagazine</h3>
                    </div>
                </a>
        </div>
        <div class="SCollum">

            <a href="https://www.enterative.com.br/comercial" target="_blank">
                <div class="card3" v-bind:style="{ backgroundImage: 'url(' + projeto2 + ')', backgroundPosition: 'center center', backgroundSize: 'cover' }">
                    <h3>Landing page Enterative</h3>
                </div>
            </a>
            
            <a href="https://need10.lojavirtualnuvem.com.br/" target="_blank">
                <div class="card4" v-bind:style="{ backgroundImage: 'url(' + projeto3 + ')', backgroundPosition: 'center center', backgroundSize: 'cover' }">
                            <h3>Ecommerce Decoutil</h3>
                </div>
            </a>
                    
        </div>

    </div>
  </div>
</template>

<script>
export default {
    name:'projetosVue',
    data(){
        return{
            projeto1:'./images/armbd.png',
            projeto2:'./images/projeto2.png',
            projeto3:'./images/deco.png',
            projeto4:'./images/mockupgb.png',

        }
    }
}
</script>

<style scoped>
body{
    overflow-x: hidden !important;

}
*{
    font-family: 'Montserrat Variable', sans-serif;


}
a{
    text-decoration: none;
}
a:link{
    text-decoration: none;
}
h1{
    text-align: center;
    margin-top: 5% !important;
    font-weight: bold !important;
    color:#2C3E50;

}

h3{
    color: #fff;
    font-weight: bold;
    margin-top: 90%;
    text-align: center;
    margin-left: 2%;

}



.ContainerProjetos{
    display: flex;
    flex-direction: row;
    
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}


.card1 h3{
    margin-top:110% !important;

   
}
.card4 h3{
    margin-top:110% !important;

}

.card2 h3{
margin-top: 85%;
}

.card3 h3{
    margin-top: 85%;

}

.card1, .card2, .card3, .card4{
    width: 590px !important;
    margin-right:30px;
    height: 300px;
    cursor: pointer;
    border: #2C3E50;
    background-color: #D9D9D9;
    color: #2C3E50;
    border-radius: 10px;
    padding: 1%;
    margin-top: 3%;
    transition: all 0.3s;
    -webkit-transition: width 1s;
    transition: width 1s;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;

    background: rgb(2,0,36);

}

.card1{
    height: 700px;
}
.card1:hover{
    transform: scale(1.1);
}
.card2{
    height: 550px;
}
.card2:hover{
    transform: scale(1.1);
}

.card3{
    height: 550px;
}
.card3:hover{
    transform: scale(1.1);
}

.card4{
    height: 700px;
}
.card4:hover{
    transform: scale(1.1);
}


@media only screen and (max-width: 869px)  {

    body{
    overflow-x: hidden !important;

}
    h1{
    text-align: center;
    margin-top: 5% !important;
    font-weight: bold;
}

h3{
    color: #fff;
    font-weight: bold;
    margin-top: 90%;
    text-align: left;
    margin-left: 2%;
}



.ContainerProjetos{
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}


h3{
    font-size: 1em;
}
.card1 h3{
    margin-top:60% !important;
}
.card4 h3{
    margin-top:60% !important;

}

.card2 h3{
    margin-top:60% !important;

}

.card3 h3{
    margin-top:60% !important;

}

.card1, .card2, .card3, .card4{
    margin-left: 3%;
    width: 360px !important;
    height: 300px;
    border: #2C3E50;
    background-color: #D9D9D9;
    color: #2C3E50;
    border-radius: 10px;
    margin-top: 3%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);

    

}

.card1{
    height: 250px;
}

.card2{
    height: 250px;
}


.card3{
    height: 250px;
}


.card4{
    height: 250px;
}


}
</style>