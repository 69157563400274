<template>
  <div id="comofunciona">

    <b-row>
        <h2 class="title">Como funciona</h2>
    </b-row>
    <b-container>
        <div class="Etapacontainer">
                <div class="CardEtapa">
                    
                    <div class="card">
                        <h2 class="numb">1</h2>
                        <br/>
                        <h3>Contato</h3>
                        <br/>
                        <p>Análise da demanda</p>
                    </div>

                    <div class="card">
                        <h2 class="numb">2</h2>
                        <br/>
                        <h3>Briefing</h3>
                        <br/>
                        <p>Coleta das informações do projeto por meio de um formulário.</p>
                    </div>


                    <div class="card">
                        <h2 class="numb">3</h2>
                        <br/>
                        <h3>Proposta comercial</h3>
                        <br/>
                        <p>Envio de proposta de acordo com as necessidades do projeto.</p>
                    </div>
                    
                </div>
                </div>
                
    </b-container>

    <b-container fluid>
        <div class="Etapacontainer">
                <div class="CardEtapa">
                    
                    <div class="card">
                        <h2 class="numb">4</h2>
                        <br/>
                        <h3>Protótipo e design</h3>
                        <br/>
                        <p>Desenvolvimento do protótipo inicial e em seguida o design. </p>
                    </div>

                    <div class="card">
                        <h2 class="numb">5</h2>
                        <br/>
                        <h3>Aprovação do protótipo</h3>
                        <br/>
                        <p>Envio do protótipo e do design para aprovação.</p>
                    </div>


                    <div class="card">
                        <h2 class="numb">6</h2>
                        <br/>
                        <h3>Desenvolvimento</h3>
                        <br/>
                        <p>Construção do projeto com base no protótipo aprovado.</p>
                    </div>

                    <div class="card">
                        <h2 class="numb">7</h2>
                        <br/>
                        <h3>Entrega do projeto</h3>
                        <br/>
                    </div>
                </div>
                </div>
    </b-container>
  </div>
</template>

<script>
export default {
name:'EtapasVue',

}
</script>

<style scoped>


.title{
    text-align: center;
    margin:0 auto;
    margin-top: 5%;
    margin-bottom: 3%;
    font-weight: bold !important;

}

.Etapacontainer{
    display: flex;
    flex-direction:row !important;
    margin: 0 auto;
    width: 100%;
    padding: 2%;

}
.CardEtapa{
    margin:0 auto;
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: center !important;
}

.card{
    width: 300px !important;
    margin-right:30px ;
    height: 300px;
    border: #2C3E50;
    background-color: #D9D9D9;
    color: #2C3E50;
    border-radius: 10px;
    padding: 1%;
}
.card p{
    font-size: 18px;
}
.card h2{ 
color:#fff;
background-color: #2C3E50;
width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 15px;
  margin: 0 auto;
  line-height: 80px;
  justify-items: center;
  margin-bottom: 5%;

}

h3{
    font-weight: bold !important;

}

.numb{
    margin-top: -30px !important;
}

@media only screen and (max-width: 869px)  {

    .title{
    text-align: center;
    margin:0 auto;
    margin-top: 5%;
    margin-bottom: 5% !important;
    font-weight: bold !important;

}
    .Etapacontainer{
    display: flex;
    margin: 0 auto;
    width: 100%;
    padding: 2%;

}
.CardEtapa{
    margin:0 auto;
    display: flex;
    width:100%;
    flex-direction:column !important;
    justify-content: center !important;
}
    .card{
    width: 100% !important;
    margin-bottom:30px ;
    height: 300px;
    border: #2C3E50;
    background-color: #D9D9D9;
    color: #2C3E50;
    border-radius: 10px;
    padding: 1%;
}

}

</style>