<template>
  <div class="footerContainer">
    <div class="itensContainer">
      
      <div class="logoFooter"><img :src="logo" class="logo" alt=""></div>

      <div class="itensFooter">
       

        <div class="contatoContainer">
          <h2>Contatos</h2>
          <p><a :href="contato" target="_">(12) 99791-6983</a></p>
          <p>contato@codecompass.com.br</p>

        </div>
      </div>
      <div class="RedesFooter">
        <h2>Redes sociais</h2>

        <div class="IconContainer">
          <a href="https://www.facebook.com/profile.php?id=61555362187138" target="_"><img :src="facebook" class="icon" alt=""></a>
          <a href="https://www.instagram.com/codecompass.com.br/" target="_"><img :src="instagram" class="icon" alt=""></a>
          <a href="https://www.linkedin.com/in/code-compass-005a172a9/" target="_"><img :src="linkedin" class="icon" alt=""></a>

        </div>
      </div>
  </div>
    <p class="footerP">CodeCompass © 2024 | Todos os Direitos Reservados.</p>
  </div>

</template>

<script>
export default {
name:'FooterVue',
data(){
  return{
    logo:'./images/footerLogo.png',
    instagram:'/images/instagram.png',
    facebook:'/images/facebook.png',
    linkedin:'/images/linkedin.png',
    contato:'https://api.whatsapp.com/send?phone=5512997916983',


  }
}
}
</script>

<style scoped>
.footerContainer{
    background-color:#292A2C ;
    height: 780px;
    z-index: 2;

    
}
.itensContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  padding: 13%;
  margin-top: 3%;
  width: 100%;
}

a{
  color: #fff !important;
  text-decoration: none;
}

a:link{
  text-decoration: none;
}
a:visited{
  text-decoration: none;
}
.itensFooter{
  color: #fff;
  font-size: 1.3em;
  width: 35%;
  text-align: center;

}
li{
  list-style-type: none;
  font-size: 1.3em;
}

.logoFooter{
  color: #fff;
  width: 35%;
  margin-top: 2%;
  text-align: start;


}
.logo{
  height: 100px;
  width: auto;
}

.RedesFooter{
  color: #fff;
  width: 35%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto;
}
.icon{
  height: 60px;
  width: auto;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 2%;

}

.contatoContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footerP{
  color: #fff;
  margin: 0 !important;
  
}
@media only screen and (max-width: 869px)  {

  
  .footerContainer{
    background-color:#292A2C ;
    height: 510px;
    
}
.itensContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 5%;
  margin-top: 7%;
  width: 100%;
  

}
.itensFooter{
  color: #fff;
  font-size: 1.3em;
  width: 100% !important;
  text-align: center;

}


.logoFooter{
  color: #fff;
  width: 100%;
  margin-top: 20%;
  text-align: start;


}
.logo{
  height:auto;
  width: 320px;
}

.RedesFooter{
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto;

 

}
.IconContainer{
  display: flex;
  flex-direction: row;
  width: 100% !important;
  justify-content: space-around;
  justify-items: center;


}

.icon{
  height: 60px;
  width: auto;
  margin-top: 2%;
  margin-right: 2%;


}
.icon a{
  margin-right: 2% !important;

}

.contatoContainer{
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  display: block;

}

.footerP{
  color: #fff;
  margin-bottom: 3%;
  padding: 2%;
}
}
</style>