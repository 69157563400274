<template>
  <div class="content" id="sobre">
        <h3>Sobre nós</h3>
        <!-- <b-container fluid> -->
            <div class="conteudo">
            <div class="text">
                <p>A CodeCompass é uma empresa de desenvolvimento e prototipação de websites, dedicada a transformar visões em realidade digital. Nossa abordagem centrada no cliente nos permite entender as necessidades específicas de cada projeto,
                garantindo soluções sob medida que impulsionam o sucesso dos nossos clientes no mundo digital.</p>
            </div>
            <div class="image">
                <img class="logoSobre" :src="logo" alt="">

            </div>

        </div>

    <!-- </b-container> -->

  </div>
</template>

<script>
export default {    
    name:'sobreVue',

    data(){
        return{
            logo:'./images/logoSobre.png'
        }
    }
}
</script>

<style scoped>
body{
    overflow-x: hidden !important;

}
h3{
    color:#fff !important;
    margin-top: 2%;
    text-align: center !important;
    margin-bottom: 5%;
    font-weight: bold;
    font-size: 32px;
}
.content{
    background-color: #2C3E50;
    height: 600px;
    padding: 1%;
    color: #fff;
    text-align: left;
    margin-bottom: 5%;
}
.conteudo{
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;

}

.text{
    width: 60%;

}
.text p{
    width: 70%;
}

.image{
    width: 40%;


}

.logoSobre{
    height: 250px;
    margin-top: -55px;
    width: auto;
}

@media only screen and (max-width: 869px)  {
    body{
    overflow-x: hidden !important;

}
    h3{
    color:#fff !important;
    margin-top: 2%;
    text-align: center !important;
    margin-bottom: 5%;
    font-weight: bold;
}
.content{
    background-color: #2C3E50;
    height: 600px;
    padding: 1%;
    color: #fff;
    text-align: center;
    margin-bottom: 5%;
}
.conteudo{
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction:column-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;

}

.text{
    width: 100%;


}
.text p{
    width: 100%;
}

.image{
    width: 100%;
    text-align: center;
    margin-top: 15%;
}
.logoSobre{
    height: 200px;
    max-width: 85%;
}
}
</style>