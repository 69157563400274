<template>
  <div id="app">
    <Navbar/>
    <Banner/>
    <Services/>
    <Etapas/>
    <sobre/>
    <Projetos/>
    <Tecnologias/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Banner from './components/Banner.vue';
import Services from './components/Services.vue';
import Etapas from './components/Etapas.vue';
import sobre from './components/sobre.vue'
import Projetos from './components/Projetos.vue';
import Tecnologias from './components/Tecnologias.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Banner,
    Services,
    Etapas,
    sobre,
    Projetos,
    Tecnologias,
    Footer,
    }
}
</script>

<style>
 * {
    scroll-behavior: smooth !important;
    behavior: smooth !important;
    -webkit-overflow-scrolling: smooth !important;
    -moz-overflow-scrolling: smooth !important;
    -ms-overflow-scrolling: smooth !important;
    -o-overflow-scrolling: smooth !important;
    font-family: 'Montserrat Variable', sans-serif;

  }
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: 'Montserrat Variable', sans-serif;

}
body{
  overflow-x: hidden !important;
  font-family: 'Montserrat Variable', sans-serif;

}
h1, h2{
  font-weight: bold !important;
  font-family: 'Montserrat Variable', sans-serif;

}

p{
  font-size: 24px;
  font-family: 'Montserrat Variable', sans-serif;

  
}

@media only screen and (max-width: 869px)  {
  body{
    overflow-x: hidden !important;
  }
p{
  font-size: 18px;
}
}



</style>
