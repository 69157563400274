<template>
  <div class="servicosArea" id="servicos">
        <div class="align-items-center">
            <h2 class="title">Serviços</h2>
            <p>Conheça nossos serviços e conte conosco para realização<br/> de seu projeto.</p>
        </div>
    <b-container fluid>
        <div class="container">
                <div class="CardContainer">
                    <div v-for="(item) in Services" :key="item.id" class="card">
                        <img class="icon" :src="item.icon" alt="">
                        <br/>
                        <h3>{{item.name}}</h3>
                        <br/>
                        <p>{{item.desc}}</p>
                    </div>
                </div>
                </div>
    </b-container>

    <div class="bannerServicos" v-bind:style="{ backgroundImage: 'url(' + imagem + ')', backgroundPosition: 'center center', backgroundSize: 'cover' }">
        <div class="bannerContent">
            <b-container class="col-12 col-xl-8">
                <h2>Quem pode utilizar os nossos serviços?</h2>
                <p>Os serviços da CodeCompass são para você que sonha em tornar seu projeto realidade, seja você dono de grande ou pequena empresa, profissional autonomo e até mesmo estudante.</p>
                <button class="btnContato"><a :href="contato" target="_blank">Fale com um especialista</a></button>
            </b-container>

        </div>
    </div>
  </div>
</template>

<script>
 export default{
        name:'ServicesVue',
        data(){
            return{
                imagem:'./images/bannerServicos.png',
                Services:[
                    {id:1, name:'Sites', icon:'./images/site.png', desc:'Desenvolvimento de sites personalizados como Landingpages, sites institucionais e portfólios.'},
                    {id:2, name:'E-commerce', icon:'./images/ecommerce.png', desc:'Desenvolvimento de lojas online eficientes e intuitivas.'},
                    {id:3, name:'Ui design', icon:'./images/ui.png', desc:'Elaboração de interfaces de acordo com a necessidade do projeto ou usuário.'},
                ],
                contato:'https://api.whatsapp.com/send?phone=5512997916983',

            }
        }
    }
</script>

<style scoped>
body{
    overflow-x: hidden !important;

}
*{
    font-family: 'Montserrat Variable', sans-serif;
    
}
a{
    text-decoration: none;
    color: #fff;
}
a:hover{
    color: #0073e6;
}
h1{
    font-family: 'Montserrat Variable', sans-serif;

}

p{
  font-size: 24px;
  
}
.title{
    text-align: center;
    margin:0 auto;
    margin-top: 5%;
    margin-bottom: 5% !important;
    font-weight: bold !important;
}
.servicosArea{
    margin-top: 6%;
}
.serviceTitle{
    text-align: center;
    margin: 0 auto  !important;
}
h1{
    text-align: center !important;
    font-weight: bold;
}

.CardContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}
.card{
    height: auto;
    width: 950px;
    margin-right: 25px;
    margin-bottom: 5%;
    border: none #292a2c;
    padding: 4%;
    border-radius: 20px;
    transition: all 0.3s;
    -webkit-transition: width 1s;
    transition: width 1s;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.card:hover{
        transform: scale(1.1);

}
.card p{
    font-size: 18px;
}

.icon{
    height: 100px;
    width: auto;
    margin: 0 auto;
}

.bannerServicos{
    height:600px;
}

.bannerContent{
    color: #fff;
    width: 100%;
    padding: 7%;
    text-align: left;

}
.bannerContent h2{
    font-weight: bold;
    

}
.bannerContent p{
    font-size: 24px;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 60%;
}


button{
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size: 0.8em;
    border: none;
    width: 300px;
    font-family: 'Montserrat' !important; 
    cursor: pointer;
    transition: width 0.3s; 
    font-size: 1.2em;
}

.btnContato{
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size:1em;
    width: 300px;
    border: solid 1px #0073e6;
    font-family: 'Montserrat' !important; 
    cursor: pointer;

    transition: all 0.3s;
    -webkit-transition: width 1s;
    transition: width 1s;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}
.btnContato:hover{
    background-color: #fff;
    color: #0073e6 !important;
    border: solid 1px #0073e6;
    transform: scale(1.1);

 }
 .btnContato:hover a{
    color: #0073e6 !important;
 }



@media only screen and (max-width: 769px)  {
    body{
    overflow-x: hidden !important;

}
    .CardContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}


.card{
    height: auto;
    width: 90%;
    margin: 7px;
    border: none #292a2c;
    border-radius: 14px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

.bannerContent{
    width: 100%;
}

.bannerServicos{
    height:490px;
}

.bannerContent{
    color: #fff;
    width: 100%;
    padding: 7%;
    text-align: center;
    margin-top: 3%;

}
.bannerContent h2{
    margin-top: 20%;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;

}
.bannerContent p{
    font-size: 16px;
    margin-top: 5%;
    width: 100% !important;
    margin-bottom: 5%;
    text-align: left;

}

button{
    color: #fff;
    background-color: #0073e6;
    border-radius: 7px;
    padding: 12px;
    font-size: 0.8em;
    border: none;
    width: 300px;
    font-family: 'Montserrat' !important; 
    cursor: pointer;
    font-size: 1em;
    text-align: center !important;
}


}


</style>